/**
 * https://wiki.openstreetmap.org/wiki/Zoom_levels
 */
export default [
    62914560,
    31457280,
    15728640,
    7864320,
    3932160,
    1966080,
    983040,
    491520,
    245760,
    122880,
    61440,
    30720,
    15360,
    7680,
    3840,
    1920,
    960,
    480,
    240,
    120,
    60,
]
